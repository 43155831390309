html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bgstyle {
  /* background: linear-gradient(136deg, #1ad7c0, #8E44AD, #ff9b11, #1F3A93, #ddd8c6, #000000, #000000);  */
  /*background: linear-gradient(
    136deg,
    #4484ce,
    #1ad7c0,
    #ff9b11,
    #9b59b6,
    #ff7f7f,
    #ecf0f1
  );*/

  background-size: 1200% 1200%;
  -webkit-animation: BackgroundAnimation 70s ease infinite;
  -moz-animation: BackgroundAnimation 70s ease infinite;
  -o-animation: BackgroundAnimation 70s ease infinite;
  animation: BackgroundAnimation 70s ease infinite;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s;
}
.navbar-white {
  background-color: rgba(234, 236, 239, 0.81);
  transition: background-color 0.2s;
}
.brand {
  color: #363636 !important;
}
.nav-link {
  transition: color, 0.2s;
}
.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.socialicons {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
  &:hover {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}

.title {
  height: 100%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;
  @media (min-width: 360px) {
    min-height: 4rem;
  }
  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    box-shadow: 0 2rem 3rem rgba($black, 0.175) !important;
    transform: translateY(-2px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}
.skills-tabs {
  flex-grow: 1;
}
.skills-tab {
  flex-grow: 1;
  text-align: center;
}
.row{
	justify-content: center;
}

.progress {
  transform-origin: left;
  & > * {
    transform: scaleX(0);
  }
}
.progress-bar-animation {
  @extend .progress;
  & > * {
    animation: progress 1.5s ease-in-out forwards;
  }
}

/*Nuevo SCSS*/
$flipDuration: .8s;

@keyframes flipF2B {
  0% {
    transform: rotateY(0deg) translateZ(0px);
  }
  10% {
    transform: rotateY(0deg) translateZ(25px);
  }
  90% {
    transform: rotateY(180deg) translateZ(5px);
  }
  100% {
    transform: rotateY(180deg) translateZ(0px);
  }
}

@keyframes flipB2F {
  0% {
    transform: rotateY(180deg) translateZ(0px);
  }
  10% {
    transform: rotateY(180deg) translateZ(-25px);
  }
  90% {
    transform: rotateY(360deg) translateZ(5px);
  }
  100% {
    transform: rotateY(360deg) translateZ(0px);
  }
}

.cardRow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card__face {
	position: relative;
	width: 100%;
  &--front {
    backface-visibility: hidden;
    animation-timing-function: ease;
    cursor: pointer;
    .flipped & {
      animation: $flipDuration flipF2B;
      animation-fill-mode: forwards;
    }
    .unflipped & {
      animation: $flipDuration flipB2F;
      animation-fill-mode: forwards;
    }
  }
  &--back {
    cursor: pointer;
    backface-visibility: hidden;
    transform: rotateY(180deg);
	position: absolute;
    width: 100%;
    height: 100%;    
    background-color: white;
    display: flex;
    align-items: center;    
    animation-timing-function: ease;
    
    .flipped & {
      animation: $flipDuration flipB2F;
      animation-fill-mode: forwards;
    }
    .unflipped & {
      animation: $flipDuration flipF2B;
      animation-fill-mode: forwards;
    }
  }
}

.card {
  margin: 1em;
  height: 320px;
  position: relative;
  overflow: hidden;
  background-size: 260px 320px;
  background-position: center center;
  perspective: 400px;
  background-color: none;
  border: 1.5px solid rgba(0, 0, 0, 0.125);
  perspective-origin: bottom;
  
  .card__frame {
    position: relative;
    background-color: #ffffffc2;
    width: 100%;
    height: 100px;
  }
  .card__center {
    border: 12px solid white;
    background-size: 260px 320px;
    background-position: -32px -32px;
    position: relative;    
    width: 100%;
    height: 218px;
    img {
      width: 100%;
      position: absolute;
    }
  }
  .card__title {
    position: relative;
    top: 0px;
    left: 12px;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 20px;
  }
  .card__description {
    padding: 1em;
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    text-align: left;
  }
  .card__flipIcon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 30px;
  }
}


  
  .skills {
	margin: 0;
	padding: 0;
	width: calc(100%);
	li {
		display: flex;
	  position: relative;
	  background-color: #888;
	  color: #fff;
	  margin: 10px 0;
	  transition: width 300ms ease-in-out;
	  flex-direction: row;
	  justify-content: space-between;
	  .collapsed & {
		width: 0 !important;
	  }
	  p {
		padding: 10px;
		margin: 0;
		font-weight: bold;
	  }
	  span {
		position: absolute;
		right: 10px;
		display: inline-block;
		width: 30px;
		top: 11px;
		text-align: right;
		font-weight: normal;
		color: #fff;
		font-size: 15px;
	  }
	}
  }
  
  
  hr {
	height: 1px;
	border: 0;
	background-color: #ccc;
	margin: 20px 0;
	padding: 0;
  }
  
  small {
	font-size: 11px;
	color: #666;
	a {
	  color: inherit;
	}
  }

  .enlace{
	border: none!important;
    font-size: 30px;
  }
  .enlace:hover{
	background: none;
	font-size: 35px;
  }
  .enlace:focus{
	border: none;
  }
  /*

  .changehov:hover{
	border-color: transparent;
	background: linear-gradient(136deg, #4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #9b59b6) 0% 0% / 1200% 1200%;
  }
  */